<template>
  <div class="content-view">
    <div class="contentdiv">
      <el-form ref="form" :model="customer" :rules="rules">
        <div class="userInfodivTop">
          <el-form-item label="姓名" prop="name">
            <el-input
              v-model="customer.name"
              type="text"
              placeholder="请输入您的真实姓名"
            />
          </el-form-item>
          <el-form-item label="手机号" prop="mobile">
            <el-input
              v-model="customer.mobile"
              type="text"
              placeholder="请输入您的手机号码"
            />
          </el-form-item>
          <el-form-item label="邮箱地址" prop="email">
            <el-input
              v-model="customer.email"
              type="text"
              placeholder="请输入您的邮箱"
            />
          </el-form-item>
          <el-form-item label="公司名称" prop="company">
            <el-input
              v-model="customer.company"
              type="text"
              placeholder="请输入您的公司名称"
            />
          </el-form-item>
          <el-form-item>
            <div class="commitBtn" @click="onSave">修改</div>
            <text class="privacy">我们尊重您的承诺，并保护您的个人信息</text>
          </el-form-item>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
// import { updateUserInfo } from "@/api/index.js";

export default {
  name: "editInfo",
  data() {
    return {
      customer: {
        name: "",
        email: "",
        mobile: "",
        companyName: ""
      },
      rules: {
        name: [{ required: true, message: "姓名必填" }],
        // email: [{ required: true, message: "邮箱必填" }],
        mobile: [{ required: true, message: "联系方式必填" }]
        // company: [{ required: true, message: "公司名称必填" }]
      }
    };
  },
  methods: {
    onSave() {
      this.$refs.form.validate(valid => {
        if (valid) {
          // updateUserInfo(this.data.customer).then(res => {
          //   console.log(res);
          //   debugger;
          // });
        }
      });

      // window.wx.miniProgram.switchTab({
      //   url: `../../../pages/index/index`
      // });
      this.$router.push({
        name: "Home"
      });
      // 调用接口进行保存
      // Http.ty_post({
      //   url: "customer",
      //   data: this.data.customer,
      //   success: res => {
      //     wx.showToast({
      //       title: "保存成功",
      //       icon: "none"
      //     });
      //     if (this.data.status == "init") {
      //       wx.switchTab({
      //         url: "../../../pages/index/index"
      //       });
      //     }
      //   }
      // });
    }
  }
};
</script>

<style lang="stylus" scoped>
.content-view {
  height: 100%;
  background-color: #f2edf4;
  padding: 10px 30px;
}

.userInfoViewTop {
  margin-top: 30px;
  width: 80%;
  height: 100%;
  background-color: #fff;
  border-radius: 5px;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 15px;
  padding-bottom: 15px;
}
.swiper{
  display: flex;
  flex-direction: row;
  height: 30px;
  margin-top: 20px;
  border: 1px solid #44287b;
  border-radius: 3px;
  background-color: #fff;
}

.commitBtn{
  background-color: #492982;
  text-align: center;
  height: 40px;
  line-height: 40px;
  margin-top: 30px;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
}

.privacy{
  color:#ccc;
  font-size: 12px;
}

.title {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
}
</style>
